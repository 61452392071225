import React from 'react';
import { Col } from 'react-grid-system';
import { Link } from 'gatsby';
import { LazyLoadImage } from 'react-lazy-load-image-component';
function TabItem({ data }) {
  return (
    <Col md={3} sm={4} xs={6} className="team-member my-10">
      <Link to={`/specialists/${data.slug}`}>
        <LazyLoadImage
          effect="blur"
          className="shadow-md rounded"
          alt={data.name}
          src={data.picture.localFile.publicURL}
        />
        <h6 className="text-center font-bold mt-5 pb-5">{data.name}</h6>
      </Link>
    </Col>
  );
}

export default TabItem;
