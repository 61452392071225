import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import ReactMarkdown from 'react-markdown';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Container, Row } from 'react-grid-system';
import { flatten } from 'lodash';

import Hero from '../components/rows/hero';
import Banner from '../images/hero-images/doctor-bios-hero.jpg';
import 'react-tabs/style/react-tabs.css';
// import tab item component
import TabItem from '../components/elements/tabItem';

const defaultTeamValue = 'Electrophysiology';

export default function Specialists({ data, displayTeam }) {
  const _displayTeam = displayTeam ? displayTeam : defaultTeamValue;

  const [selectedCate, setSelectedCate] = useState(_displayTeam);

  // Get all members of all categories
  const allMem = flatten(
    data.allStrapiTeams.edges.map((mem) => {
      const member = Object.values(mem);
      return member;
    })
  );

  const handleCategory = (cate) => {
    setSelectedCate(cate);
  };

  // Filter members by category

  const filteredTeamMems = allMem.filter(
    (mem) => mem.Category === selectedCate
  );
  // we are using the tab library because it already used it when I took it
  return (
    <Layout>
      <Seo
        title="Specialists"
        description="Learn about the cardiologists who make up PACE’s clinical team. We provide compassionate & individualized 
        care to cardiac patients while incorporating the latest technologies & innovations."
      />
      <Hero title="Specialists" banner={Banner} />
      <Container className="mb-56">
        <div className="text-center md:w-2/3 mx-auto">
          <ReactMarkdown
            children={`Our *specialist* team`}
            components={{
              p: 'h2',
            }}
            linkTarget="_blank"
          />
          <p>
            Our team of cardiologists, physician assistants, nurse
            practitioners, and genetic counsellors are available to help at our
            Barrie and Newmarket locations. We also have an echocardiography lab
            in Orillia.
          </p>
        </div>
        <Tabs className="mt-20">
          <TabList>
            <Tab onClick={() => handleCategory('Electrophysiology')}>
              Electrophysiology/Arrhythmia
            </Tab>
            <Tab onClick={() => handleCategory('GeneralCardiology')}>
              General Cardiology
            </Tab>
            <Tab onClick={() => handleCategory('InterventionalCardiology')}>
              Interventional Cardiology
            </Tab>
            <Tab onClick={() => handleCategory('CardiovascularGenetics')}>
              Cardiovascular Genetics
            </Tab>
            <Tab onClick={() => handleCategory('NursePractitioner')}>
              Nurse Practitioners/Physician Assistants
            </Tab>
            {/* <Tab onClick={() => handleCategory('CardiacWellness')}>
              Wellness Partners
            </Tab> */}
          </TabList>
          {Array.from(Array(6).keys()).map((index) => (
            <TabPanel key={index}>
              <Row>
                {filteredTeamMems && selectedCate === 'GeneralCardiology'
                  ? filteredTeamMems
                      .reverse()
                      .map((mem) => <TabItem data={mem} key={mem.id} />)
                  : filteredTeamMems.map((mem) => (
                      <TabItem data={mem} key={mem.id} />
                    ))}
              </Row>
            </TabPanel>
          ))}
        </Tabs>
      </Container>
    </Layout>
  );
}

export const query = graphql`
  query viewTeams {
    allStrapiTeams(sort: { fields: order_number }) {
      edges {
        node {
          id
          name
          Category
          slug
          picture {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`;
